// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Glosario.style';
import firstImage from 'images/contactless.png';

function Glosario(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const blueBreak = useMediaQuery('(min-width:950px)');
    const mobileDots = useMediaQuery('(min-width:900px)');
    const smallerDots = useMediaQuery('(min-width:510px)');
    const reallySmall = useMediaQuery('(min-width:340px)');

    return (
        <Box className={classes.root}>
            <Box className={classes.titleContainer}>
                <p className="title">GLOSARIO CUENCA</p>
                <br />
            </Box>
            <Box className={classes.midContainer}>
                <p className="subtitle">SISTEMA FINANCIERO Y FINTECH</p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">AFORE</p>
                <p id="text">
                    Administradora de Fondos de Ahorro para el Retiro. Es una entidad financiera en que se administran,
                    en cuentas individuales, los fondos para el retiro de todxs lxs trabajadores que están afiliadxs al
                    IMSS o al ISSSTE.
                </p>

                <p className="header">App</p>
                <p id="text">
                    Abreviatura en inglés de la palabra "Aplicación". Software diseñado para ser instalado en
                    smartphones u otros dispositivos móviles.
                </p>

                <p className="header">Aviso de privacidad</p>
                <p id="text">
                    Es el documento en el que se informa sobre los alcances y condiciones del tratamiento de tus datos
                    personales, los cuales recabamos y utilizamos únicamente con el fin de brindarte nuestros servicios.
                    Nunca compartiremos tu información con terceros sin tu consentimiento. Siempre podrás consultar
                    nuestro Aviso de Privacidad en nuestra página de internet.
                </p>

                <p className="header">Banco</p>
                <p id="text">
                    Es una institución de crédito autorizada por la CNBV para prestar los servicios de banca y crédito,
                    los cuales consisten en la captación y colocación de recursos del público, es decir, prestar y
                    recibir dinero. Existen dos tipos principalmente: Instituciones de Banca Múltiple e Instituciones de
                    Banca de Desarrollo. Por cierto, nosotros no somos un banco.
                </p>

                <p className="header">Banco de México (Banxico)</p>
                <p id="text">
                    Es el banco central en México, encargado de las políticas monetaria y crediticia. En nuestro sector,
                    es una de las autoridades que regula a las Instituciones de Tecnología Financiera y supervisa el
                    cumplimiento de la Ley Fintech.
                </p>

                <p className="header">Beneficiarixs</p>
                <p id="text">
                    Aquellas personas designadas por el/la titular de una cuenta o de un seguro para recibir los
                    recursos que le corresponden al titular al momento de su fallecimiento. De acuerdo con el artículo
                    29 de la Ley Fintech, si no se designan beneficiarios, los fondos de la cuenta se entregarán
                    únicamente mediante un procedimiento sucesorio.
                </p>

                <p className="header">Bitcoin</p>
                <p id="text">Es una de las tantas criptomonedas que se encuentran en el mercado.</p>

                <p className="header">Blockchain</p>
                <p id="text">
                    Es una cadena digital de bloques donde se produce, gestiona y almacena información certificada y que
                    se autoprotege con su propia estructura. Cada bloque contiene la información y un hash (número único
                    que se genera según la información que se almacena) y el hash del bloque anterior, lo cual hace a la
                    cadena imposible de modificar, editar o eliminar.
                </p>

                <p className="header">Bolsa de valores</p>
                <p id="text">
                    Entidad financiera cuyo objetivo es facilitar las transacciones con valores y procurar el desarrollo
                    del mercado. Realiza actividades para la negociación, oferta y demanda de valores.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Bolsa Mexicana de Valores (BMV)</p>
                <p id="text">
                    Entidad financiera que cuenta con la concesión de la Secretaría de Hacienda y Crédito Público (SHCP)
                    para organizarse y operar como bolsa de valores.
                </p>

                <p className="header">Bolsa Institucional de Valores (BIVA)</p>
                <p id="text">
                    Segunda bolsa de valores aprobada para operar en México, que ofrece una opción de cotización para
                    las medianas empresas.
                </p>

                <p className="header">Buró de Entidades Financieras (BEF)</p>
                <p id="text">
                    Es una herramienta de consulta y difusión con la que se puede conocer los productos y servicios que
                    ofrecen las entidades financieras. Su fin es que lxs usuarixs puedan compararlos y evaluarlos antes
                    de elegir uno de ellos o para conocer mejor los ya contratados. Conoce más: https://cuenca.com/buro
                </p>

                <p className="header">Capital</p>
                <p id="text">
                    Suma de bienes y recursos con los que cuenta una persona física o una empresa para operar y hacer
                    frente a sus obligaciones.
                </p>

                <p className="header">CAT</p>
                <p id="text">
                    Costo Anual Total. Es el porcentaje que, para fines informativos y de comparación, incorpora la
                    totalidad de los costos y gastos inherentes a los créditos, préstamos o financiamientos que otorgan
                    las entidades financieras.
                </p>

                <p className="header">CETES</p>
                <p id="text">
                    Certificados de Tesorería de la Nación. Instrumentos de deuda emitidos por el gobierno federal que
                    otorgan rendimientos en un plazo determinado a quienes invierten en ellos. Los CETES son una forma
                    segura de invertir.
                </p>

                <p className="header">Chip</p>
                <p id="text">
                    Es el componente físico, incluido en tu tarjeta, que sirve para transmitir los datos entre tu
                    tarjeta y la terminal. Siempre autorizado con tu NIP.
                </p>

                <p className="header">CLABE</p>
                <p id="text">
                    Clave Básica Estandarizada. Es un número de 18 dígitos, único; sirve para identificar tu cuenta y
                    para enviar y recibir transferencias SPEI.
                </p>

                <p className="header">Clave de rastreo</p>
                <p id="text">
                    Es un identificador único, rastreable, que se genera al realizar una transferencia SPEI a otras
                    instituciones financieras. En Cuenca las puedes identificar de la siguiente forma: CUENCA + 12
                    dígitos.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Comisión Nacional Bancaria y de Valores (CNBV)</p>
                <p id="text">
                    Es la autoridad financiera encargada de la planeación, coordinación, evaluación, vigilancia y
                    regulación de todo el sistema financiero, incluyendo a las Instituciones de Tecnología Financiera
                    como Cuenca.
                </p>

                <p className="header">
                    Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF)
                </p>
                <p id="text">
                    Es una de las autoridades supervisoras para las Fintech. Su objetivo es proteger tus intereses como
                    usuarix de un servicio financiero, así como llevar un registro de los contratos de adhesión de las
                    entidades financieras.
                </p>

                <p className="header">Comisión Nacional del Sistema de Ahorro para el Retiro (CONSAR)</p>
                <p id="text">
                    Es la autoridad que regula todo el Sistema de Ahorro para el Retiro para que cada ahorrador
                    construya su patrimonio pensionario en un sistema sólido, confiable e incluyente. Para ello,
                    establece reglas para las AFORE: vigila que resguarden e inviertan los fondos de los trabajadores
                    adecuadamente, que brinden información a los mismos y está facultada para imponer multas y sanciones
                    a estas entidades.
                </p>

                <p className="header">Comprobante Electrónico de Pago (CEP)</p>
                <p id="text">
                    Documento emitido por Banxico que sirve como respaldo de que una transferencia fue realizada con
                    éxito a la cuenta a la que envías los fondos. Puedes obtener tu CEP en banxico.org.mx/cep
                </p>

                <p className="header">Contactless</p>
                <p id="text">
                    tecnología que te permite realizar pagos, con solo acercar la tarjeta a la terminal. En Cuenca está
                    topado a $400.00 MXN. Generalmente está representado por el símbolo:&nbsp;
                    <img src={firstImage} />
                </p>

                <p className="header">Contrato de Adhesión</p>
                <p id="text">
                    documento legal que establece los términos y condiciones para la contratación de productos y
                    servicios financieros. Las entidades financieras deben registrar sus contratos de adhesión en el
                    Registro de Contratos de Adhesión (RECA) a cargo de la CONDUSEF.
                    <br />
                    Puedes encontrar el contrato de adhesión de Cuenca en nuestra página de internet
                    https://cuenca.com/contrato-adhesion y en el portal de CONDUSEF
                    https://phpapps.condusef.gob.mx/reca/_index.php.
                </p>

                <p className="header">Crédito</p>
                <p id="text">
                    Es la operación mediante la cual un acreditante pone a disposición de un acreditadx una cantidad de
                    dinero que debe ser restituida en el plazo establecido más intereses, gastos y/o comisiones.
                </p>

                <p className="header">Criptomonedas</p>
                <p id="text">
                    Monedas virtuales intangibles, también conocidas como activos virtuales. Es la representación de un
                    valor registrado electrónicamente que se utiliza como medio de pago y cuya transferencia se da
                    únicamente por medios electrónicos.
                </p>

                <p className="header">Cuenca</p>
                <p id="text">
                    Somos una fintech, damos acceso a servicios financieros a todxs. Contamos con autorización de la
                    CNBV para operar como IFPE.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Cuenta</p>
                <p id="text">
                    La cuenta se crea al realizar tu registro en Cuenca. Ahí están concentrados tu información y fondos.
                </p>

                <p className="header">CVC/CVV</p>
                <p id="text">
                    Código de seguridad, intransferible, de tu tarjeta física o digital. Te permite autorizar pagos en
                    línea con tus tarjetas.
                </p>

                <p className="header">CVC/CVV dinámico</p>
                <p id="text">
                    Código de seguridad, intransferible, de tu tarjeta física o digital que se renueva cada cierto
                    tiempo.. Te permite autorizar pagos en línea con tus tarjetas.
                </p>

                <p className="header">Depósito bancario de dinero</p>
                <p id="text">
                    Operación que únicamente pueden realizar bancos, consistente en la guardia y custodia de una
                    cantidad de dinero de un cliente, ya sea persona física o moral. Hay cuatro tipos de depósito:
                    <li id="text">A la vista (retirable cuando el cliente lo solicite).</li>
                    <li id="text">Retirable en días preestablecidos.</li>
                    <li id="text">Ahorro (ofrece rendimientos).</li>
                    <li id="text">A plazo.</li>
                </p>

                <p className="header">Deuda</p>
                <p id="text">Pasivo, obligación o compromiso de pago a cargo de una persona o empresa frente a otra.</p>

                <p className="header">Divisas</p>
                <p id="text">
                    Denominación de la moneda de cualquier país, por ejemplo: pesos mexicanos (MXN), dólares
                    estadounidenses (USD) o libra esterlina (GBP).
                </p>

                <p className="header">Domiciliación</p>
                <p id="text">
                    Aceptación expresa de un cliente de una entidad financiera para que un tercero realice cargos
                    automáticos a su cuenta.
                </p>

                <p className="header">Emisor de tarjetas</p>
                <p id="text">
                    Aquella institución que emite tarjetas que funcionan como medio de pago. Se encarga de recibir y
                    responder solicitudes de autorización de pago con tarjetas. El emisor de nuestras tarjetas de débito
                    es Cuenca Tecnología Financiera, S.A. de C.V., IFPE.
                </p>

                <p className="header">Emisora</p>
                <p id="text">
                    Empresa que para financiarse emite y coloca valores y/o acciones en el Mercado de Valores para que
                    inversionistas los adquieran.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Entidad financiera</p>
                <p id="text">
                    Aquellas entidades que realicen actividades respecto de las cuales la CNBV, la CNSF o la CONSAR
                    ejerzan facultades de supervisión. Por ejemplo: bancos, Instituciones de Tecnología Financiera,
                    casas de bolsa, aseguradoras, AFOREs, etcétera.
                </p>

                <p className="header">Estado de cuenta</p>
                <p id="text">
                    Documento en el que consta el detalle de las operaciones que celebren los clientes con las ITF u
                    otras entidades financieras.
                </p>

                <p className="header">Dinero Fiduciario</p>
                <p id="text">
                    También conocido como fiat money, es aquel sin valor intrínseco. La gente acepta el dinero porque
                    confía en que lo podrá usar en otras transacciones. Por ejemplo, las monedas representan un valor
                    distinto al metal con el que fueron acuñadas.
                </p>

                <p className="header">Fintech</p>
                <p id="text">
                    <b>FIN</b>anzas + <b>TEC</b>nología
                </p>

                <p className="header">Fondos de pago electrónico (FPE)</p>
                <p id="text">
                    Aquellos contabilizados en un registro electrónico de cuentas transaccionales que al efecto lleve
                    una Institución de Fondos de Pago Electrónico y que queden referidos a un valor monetario o a un
                    número de unidades de un activo virtual, y sean aceptados por un tercero.
                </p>

                <p className="header">Garantía</p>
                <p id="text">
                    Objeto o bien que asegura a quien otorga un crédito que el deudor pague. Por ejemplo, en créditos
                    hipotecarios se da en garantía un bien inmueble.
                </p>

                <p className="header">Historial crediticio</p>
                <p id="text">
                    Es un perfil financiero que permite a quienes otorgan créditos saber cómo has administrado tu dinero
                    en el pasado, en específico, respecto de los créditos de los cuales has sido titular. Esto incluye
                    tarjetas de crédito, créditos personales, créditos hipotecarios, entre otros.
                </p>

                <p className="header">Índice Nacional de Precios al Consumidor (INPC)</p>
                <p id="text">
                    Mide la variación de los precios de una canasta básica de bienes y servicios representativa del
                    consumo de los hogares a través del tiempo.
                </p>

                <p className="header">Inflación</p>
                <p id="text">
                    Es el crecimiento sostenido y generalizado de los precios de los bienes y servicios de un país.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Instituciones de Financiamiento Colectivo (IFC)</p>
                <p id="text">
                    Son otro tipo de ITF reguladas por la Ley Fintech, distintas a Cuenca. Con autorización de la CNBV
                    realizan actividades para poner en contacto a personas a través de medios digitales, con el fin de
                    que se otorguen financiamientos entre ellas. Las operaciones de financiamiento que se realizan a
                    través de ellas pueden ser:
                    <li id="text">Para otorgar préstamos o créditos.</li>
                    <li id="text">Para adquirir títulos representativos de capital de una empresa.</li>
                    <li id="text">Para obtener regalías o ser copropietarios de un proyecto o negocio.</li>
                </p>

                <p className="header">Instituciones de Fondos de Pago Electrónico (IFPE)</p>
                <p id="text">
                    Instituciones como Cuenca que, con autorización de la CNBV, prestan los siguientes servicios:
                    <li id="text">
                        La emisión, redención y transmisión de fondos registrados electrónicamente para hacer pagos o
                        transferencias y referidos a un valor monetario.
                    </li>
                    <li id="text">
                        Se pueden realizar operaciones en moneda nacional o, sujeto a autorización de Banxico, en moneda
                        extranjera o activos virtuales.
                    </li>
                    <li id="text">Pueden actuar como transmisores de dinero.</li>
                    <li id="text">Podrán comercializar, emitir o administrar medios de disposición.</li>
                </p>

                <p className="header">Instituciones de Tecnología Financiera (ITFs)</p>
                <p id="text">
                    Entidades que, bajo una regulación específica, ofrecen servicios financieros a través de
                    aplicaciones o medios informáticos. Las hay de dos tipos: Instituciones de Fondos de Pago
                    Electrónico (IFPE) e Instituciones de Financiamiento Colectivo (IFC).
                </p>

                <p className="header">Instituto para la Protección de Ahorro Bancario (IPAB)</p>
                <p id="text">
                    Se encarga de proteger y garantizar los depósitos de los pequeños y medianos ahorradores, únicamente
                    en bancos. ¿Por qué en fintech no? Porque los fondos de los usuarios de Fintech se encuentran
                    siempre seguros, pues estas instituciones no colocan (prestan) los recursos que captan (reciben) de
                    los clientes.
                </p>

                <p className="header">Interés</p>
                <p id="text">
                    Valor para medir la utilidad, rendimiento de los ahorros e inversiones o el costo de un crédito.
                    Normalmente se mide en términos porcentuales.
                </p>

                <p className="header">Inversión</p>
                <p id="text">Es la colocación de fondos o recursos con el fin de obtener beneficios futuros.</p>

                <p className="header">Ley Fintech</p>
                <p id="text">
                    Su nombre real es Ley para Regular a las Instituciones de Tecnología Financiera (ITF). Tiene el
                    objetivo de ofrecer una mayor certeza jurídica a usuarios de servicios financieros a través de
                    plataformas digitales.
                </p>

                <p className="header">Línea de crédito</p>
                <p id="text">
                    Cantidad de dinero máxima que las instituciones financieras ponen a disposición del cliente mediante
                    la firma de un contrato de crédito en cuenta corriente (ej. tarjetas de crédito). El cliente puede
                    disponer o no de la totalidad de la línea de crédito e ir restituyendo el monto por medio de pagos.
                </p>

                <p className="header">Limoncello</p>
                <p id="text">Cuenquiperri. Es un ser comelón que habita en Cuenca.</p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Luisa</p>
                <p id="text">
                    Agente de soporte de Cuenca, todos los días, las 24 horas. Ente todopoderoso, su misión en la vida
                    es ayudar a los clientes en Cuenca.
                </p>

                <p className="header">Mercado de valores</p>
                <p id="text">
                    Es la parte del sistema financiero en la que se emiten, colocan, negocian y amortizan valores. En
                    éste participan empresas emisoras, inversionistas, autoridades supervisoras y reguladoras e
                    intermediarios bursátiles.
                </p>

                <p className="header">Non Fungible Tokens (NFTs)</p>
                <p id="text">
                    Activos virtuales únicos e inseparables que se asignan a un activo digital (una foto, una canción,
                    una imagen, etc.) mediante la tecnología blockchain.
                </p>

                <p className="header">Número de identificación bancaria (BIN)</p>
                <p id="text">
                    Primeros 6 dígitos de tus tarjetas. Sirven para identificar la institución emisora y el tipo de
                    tarjeta (crédito/débito).
                </p>

                <p className="header">Número de identificación personal (NIP)</p>
                <p id="text">
                    Es único para tu tarjeta física, te sirve para autorizar compras en terminal o retiros de efectivo
                    en cajero. Cada tarjeta tiene un NIP único, nunca es el mismo.
                </p>

                <p className="header">Pagaré con Rendimiento Liquidable al Vencimiento</p>
                <p id="text">
                    Documento donde se documenta un préstamo que hace un cliente a un banco. El banco suscribe el pagaré
                    por el monto del préstamo más un rendimiento.
                </p>

                <p className="header">Producto Interno Bruto (PIB)</p>
                <p id="text">
                    Valor monetario de todos los bienes y servicios producidos en una economía en un período de tiempo
                    determinado. En México se publica trimestralmente.
                </p>

                <p className="header">Registro de Contratos de Adhesión (RECA)</p>
                <p id="text">
                    Es el registro a cargo de CONDUSEF en el cual las entidades financieras dan a conocer los Contratos
                    de Adhesión de los productos y servicios que ofrecen.
                </p>

                <p className="header">Remesas</p>
                <p id="text">Es el dinero que envía una persona que radica en el extranjero a su país de origen.</p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Rendimiento</p>
                <p id="text">Utilidad o beneficio que se obtiene por una inversión.</p>

                <p className="header">Riesgo</p>
                <p id="text">
                    Incertidumbre o posibilidad de que los rendimientos derivados de una inversión no se den de acuerdo
                    con la expectativa del inversionista.
                </p>

                <p className="header">Secretaría de Hacienda y Crédito Público (SHCP)</p>
                <p id="text">
                    Dependencia encargada de dirigir las políticas fiscales, financieras, de gastos, ingresos y deuda
                    pública del gobierno federal. Es una de las autoridades reguladoras para las fintech, además, de
                    ella depende la Comisión Nacional Bancaria y de Valores (CNBV).
                </p>

                <p className="header">Secreto financiero</p>
                <p id="text">
                    Obligación que tienen las entidades financieras de no revelar información sobre sus clientes y las
                    operaciones que realizan. Solo si lo ordena una autoridad judicial la entidad podría revelar dicha
                    información.
                </p>

                <p className="header">Seguridad de la información</p>
                <p id="text">
                    Medidas tecnológicas adoptadas por Cuenca para que tu información personal y transaccional se
                    encuentre segura en todo momento.
                </p>

                <p className="header">Seguro</p>
                <p id="text">
                    Es una operación que únicamente puede ser llevada a cabo a través de una Institución de Seguros
                    (aseguradora), autorizada por la Comisión Nacional de Seguros y Fianzas (CNSF). En caso de que se
                    presente un acontecimiento futuro e incierto la aseguradora, contra el pago de una cantidad de
                    dinero (prima), se obliga a resarcir al cliente un daño o a pagar una suma de dinero.
                </p>

                <p className="header">SOCAP</p>
                <p id="text">
                    Sociedad Cooperativa de Ahorro y Préstamo. Es una entidad que busca promover la inclusión financiera
                    a través de la realización de operaciones de ahorro y préstamo con sus socios, con autorización de
                    la CNBV.
                </p>

                <p className="header">Sociedad de Información Crediticia (SIC)</p>
                <p id="text">
                    Son entidades financieras que recopilan, manejan y envían información relativa al historial
                    crediticio de personas físicas y morales. Para llevar a cabo estas actividades, estas empresas deben
                    obtener autorización de la Secretaría de Hacienda y Crédito Público. Ejemplos de SIC son Buró de
                    Crédito y Círculo de Crédito.
                </p>

                <p className="header">SOFIPO</p>
                <p id="text">
                    Sociedad Financiera Popular. Es una entidad de microfinanzas que, con autorización de la CNBV,
                    realiza operaciones de ahorro y préstamo con sus socios y clientes.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">SOFOM</p>
                <p id="text">
                    Sociedad Financiera de Objeto Múltiple. Es una sociedad anónima registrada ante CONDUSEF, que
                    realiza actividades de otorgamiento de crédito, arrendamiento financiero o factoraje financiero.
                    <li id="text">
                        Pueden ser reguladas o no reguladas. Son Sofomes reguladas aquellas que tengan vínculos
                        patrimoniales con bancos, SOFIPOs, SOCAPs o uniones de crédito; sean emisoras de valores de
                        deuda conforme a la Ley del Mercado de Valores o aquellas que sin colocarse en los supuestos
                        anteriores, obtengan autorización de la CNBV para ser reguladas. Son Sofomes no reguladas todas
                        las demás.
                    </li>
                </p>

                <p className="header">SIEFORE</p>
                <p id="text">
                    Sociedad de Inversión Especializada en Fondos para el Retiro. Es un fondo de inversión en el que las
                    AFORE invierten los recursos de los trabajadores para obtener rendimientos para poder incrementar
                    sus pensiones.
                </p>

                <p className="header">Simuladores y Calculadoras</p>
                <p id="text">
                    Herramientas desarrolladas por CONDUSEF para que los usuarios conozcan y comparen los diferentes
                    servicios o productos financieros en el mercado (como créditos, seguros, tarjetas, cuentas de
                    ahorro, entre otros) antes de contratarlos.
                </p>

                <p className="header">SIPRES</p>
                <p id="text">
                    Es el Sistema Registro de Prestadores de Servicios Financieros a cargo de CONDUSEF. Como entidad
                    financiera, nos encontramos debidamente registrados.
                </p>

                <p className="header">Sistema de Pagos Electrónicos Interbancarios (SPEI)</p>
                <p id="text">
                    Permite a sus participantes (Fintechs, bancos y otras instituciones financieras) enviar y recibir
                    dinero entre sí para brindar a sus clientes el servicio de transferencias electrónicas.
                </p>

                <p className="header">Sistema de Transferencias y Pagos (STP)</p>
                <p id="text">
                    Entidad financiera mediante la cual hacemos y recibimos transferencias en Cuenca. Para realizar
                    transferencias, debes indicar tu CLABE y como entidad de destino STP.
                </p>

                <p className="header">Tarjeta</p>
                <p id="text">
                    Es uno de los medios de disposición de los fondos de tu cuenta. Recuerda que tus fondos nunca se
                    almacenan en tus tarjetas, siempre están en tu cuenta.
                </p>

                <p className="header">Tarjeta de crédito</p>
                <p id="text">
                    Con estas tarjetas puedes hacer las compras que desees sin exceder el límite de crédito. Cada mes,
                    debes cubrir al menos una parte de lo que gastaste. Al no pagar el total de lo gastado en ese
                    periodo, el acreedor te cobrará una tasa de interés sobre el saldo que no pagaste. Existen diversos
                    tipos de tarjetas de crédito, con diferentes beneficios, comisiones y tasas de interés.
                </p>

                <p className="header">Tarjeta de débito</p>
                <p id="text">
                    Este tipo de tarjetas te permite disponer del dinero que tienes en tu cuenta en el momento que lo
                    necesites. Con estas tarjetas se pueden hacer pagos en comercios que se cargan al saldo de tu
                    cuenta, es decir, no puedes gastar más de lo que tienes en la cuenta. Puedes hacer retiros de
                    efectivo en cajeros automáticos.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <p className="header">Tarjeta digital</p>
                <p id="text">
                    Alternativa digital a una tarjeta física, puede utilizarse para compras y pagos en línea.
                </p>

                <p className="header">Tarjeta física</p>
                <p id="text">
                    Es el plástico donde están impresos los datos de tu tarjeta el cual te sirve para hacer pagos de
                    manera presencial (terminales y cajeros). La numeración de tu tarjeta física es diferente a la
                    tarjeta virtual.
                </p>

                <p className="header">Tarjetahabiente</p>
                <p id="text">Titular de una tarjeta de crédito o débito.</p>

                <p className="header">Tarjetas Cuenca</p>
                <p id="text">
                    Entidad emisora de tu tarjeta de crédito. Accede al sitio para conocer más:
                    https://tarjetascuenca.com/
                </p>

                <p className="header">Transacción</p>
                <p id="text">
                    Todo tipo de movimiento de entrada y salida que está relacionada con tu cuenta y tus fondos.
                </p>

                <p className="header">UNE</p>
                <p id="text">
                    Unidad Especializada de Atención a Usuarios. A través de ésta podrás presentar consultas,
                    aclaraciones y reclamaciones respecto de nuestros productos y servicios: https://cuenca.com/une
                </p>

                <p className="header">Unidad de Medida y Actualización (UMA)</p>
                <p id="text">
                    Unidad cuyo valor equivalente en pesos se determina de conformidad con la Ley para Determinar el
                    Valor de la Unidad de Medida y Actualización.
                </p>

                <p className="header">Unidades de Inversión (UDI)</p>
                <p id="text">
                    Es una unidad de valor, cuyo equivalente en pesos es establecido por Banxico, con base en la
                    inflación. Fueron creadas en 1995 e inicialmente eran utilizadas para el otorgamiento de créditos
                    hipotecarios. Hoy en día, nos sirven para solventar todo tipo de operación o acto mercantil.
                </p>

                <p className="header">Valores</p>
                <p id="text">
                    Cualquier título representativo de capital o de deuda susceptible de circular en el mercado de
                    valores.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Glosario);
