import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '56.25%',
            marginTop: '200px',
            marginLeft: '30%',
            [theme.breakpoints.up(1441)]: {
                maxWidth: '810px',
                margin: '0 auto 580px auto',
                marginTop: '200px',
            },
            [theme.breakpoints.down(1200)]: {
                width: '75%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(900)]: {
                width: '78%',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(510)]: {
                marginLeft: '21%',
            },
        },
        titleContainer: {
            marginRight: '9.3%',
            '& .title': {
                fontSize: '59.57px',
                fontWeight: 900,
                color: '#3D4274',
                letterSpacing: '7px',
                marginBottom: '0',
            },
            [theme.breakpoints.down(1000)]: {
                marginBottom: '65px',
                marginRight: '0',
                '& .title': {
                    fontSize: '55.24px',
                    letterSpacing: '6.34px',
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '100px',
                '& .title': {
                    fontSize: '52.24px',
                    letterSpacing: '6px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginBottom: '70px',
                '& .title': {
                    fontSize: '45px',
                    letterSpacing: '5.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .title': {
                    fontSize: '29.85px',
                    letterSpacing: '3.43px',
                },
            },
        },
        midContainer: {
            '& .subtitle': {
                fontSize: '32.07px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '2px',
            },
            '& .header': {
                fontSize: '18.33px',
                fontWeight: 900,
                color: '#3D4975',
                letterSpacing: '2px',
                marginBottom: '10px',
                marginTop: '31px',
            },
            '& #text': {
                width: '80%',
                fontSize: '15px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.75px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(1000)]: {
                '& #subtitle': {
                    fontSize: '29.13px',
                    letterSpacing: '2.11px',
                },
            },
            [theme.breakpoints.down(769)]: {
                '& .header': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& .subtitle': {
                    fontSize: '22.13px',
                    letterSpacing: '2px',
                },
                '& .header': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                },
            },
            [theme.breakpoints.down(650)]: {
                '& .subtitle': {
                    fontSize: '22.13px',
                    letterSpacing: '1.8px',
                },
                '& .header': {
                    letterSpacing: '0.5px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .subtitle': {
                    fontSize: '22.13px',
                    letterSpacing: '1.14px',
                },
                '& .header': {
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
            },
        },
    });
